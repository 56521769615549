import React from "react";
import { concatClassNames as cn } from "@sys42/utils";

import Layout from "../layouts/layout";

import { HeaderTitle } from "../components/HeaderTitle";
import { HtmlContent } from "../components/HtmlContent";
import { WavySeparator } from "../components/WavySeparator";

import * as helperStyles from "./helpers.module.css";
import * as styles from "./styles/privacy.module.css";

const Privacy = () => {
  return (
    <Layout title={"Privacy Policy"}>
      <article>
        <HeaderTitle
          overline={"Latest update: August 29, 2024"}
          titleWidth={"13em"}
        >
          Privacy Policy of Userbrain
        </HeaderTitle>

        <WavySeparator style={{ paddingTop: "var(--spacing-md)" }} />

        <div className={helperStyles.container}>
          <HtmlContent
            className={cn(
              helperStyles.spacingMd,
              styles.privacyHtml,
              helperStyles.widthHtmlContent
            )}
          >
            {/*
          1. https://document.online-convert.com/convert/doc-to-html
          2. https://www.htmlwasher.com/
          3. search replace clean the heading ols
          4. shift heading levels
          */}
            <strong>Privacy Policy of Userbrain</strong>
            <p>
              This document can be printed for reference by using the print
              command in the settings of any browser.
            </p>

            <h2>COMMITMENT</h2>
            <p>
              Userbrain GmbH (hereinafter ”Userbrain” or “Controller” or “we” or
              “our”) as Controller of the processing of personal data within the
              scope of application of the General Data Protection Regulation
              (hereinafter the “GDPR“) takes the protection of personal data of
              Userbrains clients (hereinafter „User“ or „you“) as its highest
              priority. With regard to the processing of personal data,
              Userbrain adheres to all provisions of the GDPR, Austrian Data
              Protection Act, as well as other relevant national and EU
              provisions, and is committed to achieving the best possible
              transparency. Userbrain processes personal data in accordance with
              the principles of lawfulness, fairness, transparency, accuracy,
              purpose limitation, data minimization, storage limitation, and
              integrity and confidentiality.
            </p>

            <h2>Data Controller</h2>
            <p>
              <strong>Userbrain GmbH</strong> Frauengasse 7 8010 Graz AUSTRIA
            </p>
            <p>
              <strong>email:</strong>&nbsp;support@Userbrain.com
            </p>

            <h2>Types of Data collected</h2>
            <p>
              Personal data is any information concerning an identified or
              identifiable person.
            </p>
            <p>
              Userbrain collects, by itself or through third parties the
              following personal data:
            </p>
            <ol>
              <li>
                <p>
                  User data: e.g. email address, postal address, phone number,
                  first name, last name, contact information of the employer,
                  gender
                </p>
              </li>
              <li>
                <p>
                  Log files: e.g. IP addresses; browser type, browser version,
                  used OS, referrer URL, hostname of the accessing computer,
                  date and time of the server request
                </p>
              </li>
              <li>
                <p>
                  Usage data: e.g. IP addresses, URI addresses, the time of the
                  request, the method utilized to submit the request to the
                  server, the size of the file received in response, the
                  numerical code indicating the status of the server's answer
                  (successful outcome, error, etc.), the country of origin, the
                  features of the browser and the operating system utilized by
                  the User, the various time details per visit (e.g., the time
                  spent on each page within the Application) and the details
                  about the path followed within the Application with special
                  reference to the sequence of pages visited
                </p>
              </li>
              <li>
                <p>
                  payment information: banking information, credit card
                  information, digital-wallet information
                </p>
              </li>
              <li>
                <p>
                  task data: data of the performed task e.g. video files, audio
                  files, transcritps
                </p>
              </li>
            </ol>
            <p>
              More information about the collected personal data is provided in
              the dedicated sections of this privacy policy or by specific
              explanation texts displayed prior to the Data collection.
            </p>
            <p>
              Users are responsible for any third-party Personal Data obtained,
              published or shared through Userbrain. Users ought to have the
              third party's consent to provide the Data to the Data Controller.
            </p>

            <h2>Methods of processing</h2>
            <p>
              The Controller takes appropriate security measures to prevent
              unauthorized access, disclosure, modification, or unauthorized
              destruction of the Data. The data processing is carried out using
              computers and/or IT enabled tools, following organisational
              procedures and modes strictly related to the purposes indicated.
            </p>
            <p>
              If Users are not willing to provide their personal data, Userbrain
              may not be able to perform its services. An existing contract,
              under these circumstances, may no longer be executed and may have
              to be terminated.
            </p>

            <h2>Recipient</h2>
            <p>Personal data may be accessible or passed to</p>
            <ol>
              <li>
                <p>
                  common industry service providers such as postal service
                  providers
                </p>
              </li>
              <li>
                <p>certified public accountant, tax accountant or attorneys</p>
              </li>
            </ol>
            <p>
              The Controller passes personal data to data processors if this
              transfer of data is necessary to fulfil the task at hand. When
              selecting processors, the Controller ensures compliance with the
              provisions on data protection. In addition, the processors are
              bound by contracts, ensuring personal data is being processed
              confidentially and carefully. Personal data may also be accessible
              or passed to the entities described in section 10 of this privacy
              policy.
            </p>

            <h2>Legal basis of processing</h2>
            <p>
              The Controller may process personal data relating to Users if one
              of the following applies:
            </p>
            <ul>
              <li>
                <p>
                  Users have given their consent for one or more specific
                  purposes Art 6 (1) (a) GDPR.
                </p>
              </li>
              <li>
                <p>
                  Provision of data is necessary for the performance of an
                  agreement with the User and/or for any pre-contractual
                  obligations thereof Art 6 (1) (b) GDPR.
                </p>
              </li>
              <li>
                <p>
                  Processing is necessary for compliance with a legal obligation
                  to which the Controller is subject to Art 6 (1) (c) GDPR.
                </p>
              </li>
              <li>
                <p>
                  Processing is necessary for the purposes of the legitimate
                  interests pursued by the Controller Art 6 (1) (f) GDPR.
                </p>
              </li>
            </ul>
            <p>
              Complete details on the legal basis of processing personal data
              are provided in section 10 of this privacy policy.
            </p>

            <h2>Transfer to third countries</h2>
            <p>
              The data is processed at the Controller's operating offices and in
              any other places where the parties involved in the processing are
              located (e.g. service providers). Against this background, data is
              also transferred to third countries (i.e. outside the EU). There
              is currently no adequacy decision by the EU Commission for
              Australia in accordance with Article 45 Paragraph 3 GDPR. This
              means that your data will be transferred to a country that does
              not offer an adequate level of protection in terms of security of
              your personal data compared to the EU. To protect user’s data we
              have concluded standard contractual clauses with companies from
              third countries for which there is no adequacy decision. To which
              data importer in Australia your data is transmitted to depends on
              which services are used. These services are listed below.
            </p>
            <p>
              If a transfer to third countries, in particular Australia, shall
              not take place, Users must not use the respective services of the
              Controller. It is therefore the responsibility of the respective
              User whether a transfer to third countries takes place.
            </p>

            <h2>Retention time</h2>
            <p>
              Personal data shall be processed and stored for as long as
              required based on the purpose they have been collected for.
            </p>
            <p>Therefore:</p>
            <ul>
              <li>
                <p>
                  Personal data collected for purposes related to the
                  performance of a contract between the Controller and the User
                  shall be retained until such contracts have been fully
                  performed and beyond that only in accordance with legal
                  retention periods or other time limits within which claims can
                  be asserted.
                </p>
              </li>
              <li>
                <p>
                  Personal data collected for the purposes of the Controller’s
                  legitimate interests shall be retained as long as needed to
                  fulfil such purposes. Users may find specific information
                  regarding the legitimate interests pursued by the Controller
                  within the relevant sections of this document.
                </p>
              </li>
              <li>
                <p>
                  Controller retain personal data for as long as the User has
                  given consent to such processing and for as long as the User
                  is able make any claims against Userbrain.
                </p>
              </li>
              <li>
                <p>
                  Controller may be obliged to retain personal data as required
                  to do so for as long as the performance of a legal obligation
                  or upon order of an authority.
                </p>
              </li>
            </ul>
            <p>
              Once the retention period expires, personal data shall be deleted.
            </p>

            <h2>The purposes of processing</h2>
            <p>
              The data concerning the User is collected to allow the Controller
              to provide its service, comply with its legal obligations, respond
              to enforcement requests, protect its rights and interests (or
              those of its Users or third parties), detect any malicious or
              fraudulent activity, as well as the following: displaying content
              from external platforms, remarketing and behavioural targeting,
              analytics, User database management, interaction with live chat
              platforms, handling payments, SPAM protection, hosting and backend
              infrastructure, contacting the User, content commenting, managing
              contacts and sending messages, heat mapping and session recording,
              Tag management and infrastructure monitoring.
            </p>
            <p>
              For specific information about the personal data used for each
              purpose, the User may refer to section 10 of this privacy policy.
            </p>

            <h2>Detailed information on the processing of Personal Data</h2>
            <p>
              Personal data is collected for the following purposes and
              transferred to the respective service provider:
            </p>

            <h3>Handling payments</h3>

            <p>
              Payment processing services enable Userbrain to process payments
              by credit card, bank transfer or other means. The payment
              transaction is processed by one of the following external payment
              service providers. To ensure greater security, Userbrain shares
              only the information necessary to execute the transaction with the
              financial intermediaries handling the transaction.
            </p>

            <h4>Stripe</h4>

            <h5>Name and contact information:</h5>

            <p>
              Stripe Payments Europe Ltd, North Wall Quay Dublin 1, 662880
              Ireland.
            </p>
            <p>e-mail: complaints@stripe.com</p>

            <h5>Purpose of the processing activity:</h5>

            <p>
              This service is used to process payment transactions with
              customers.
            </p>

            <h5>Personal Data processed:</h5>

            <p>User data, log files and payment information</p>

            <h5>Legal Basis:</h5>

            <p>
              The process of the payment transaction is necessary for the
              performance of the contract according to Art 6 (1) (b) GDPR
            </p>

            <h5>Further information</h5>

            <p>
              <a href="https://stripe.com/at/privacy">Privacy Policy</a>
            </p>

            <h4>PayPal</h4>

            <h5>Name and contact information:</h5>

            <p>
              PayPal (Europe) S.à r.l. et Cie, S.C.A., 22-24 Boulevard Royal,
              L-2449 Luxembourg
            </p>
            <p>e-mail: impressum@paypal.com</p>

            <h5>Purpose of the processing activity:</h5>

            <p>
              This service is used to process payment transactions with
              customers.
            </p>

            <h5>Personal Data processed:</h5>

            <p>User data, log files and payment information</p>

            <h5>Legal Basis:</h5>

            <p>
              The process of the payment transaction is necessary for the
              performance of the contract according to Art 6 (1) (b) GDPR
            </p>

            <h5>Further information:</h5>

            <p>
              <a href="https://www.paypal.com/myaccount/privacy/privacyhub?locale.x=de_AT">
                Privacy Policy
              </a>
            </p>

            <h4>Baremetrics</h4>

            <h5>Name and contact information:</h5>

            <p>
              Baremetrics Inc., 548 Market Street, San Francisco, CA 94104, USA
            </p>
            <p>e-mail: PrivacyShield@Baremetrics.com</p>

            <h5>Purpose of the processing activity:</h5>

            <p>
              This service is used to analyse payment transactions with
              customers.
            </p>

            <h5>Personal Data processed:</h5>

            <p>User data, log files and payment information</p>

            <h5>Legal Basis:</h5>

            <p>
              Data processing is based on the legal ground of legitimate
              interest (Art 6 (1) (f) GDPR). Userbrain has a legitimate interest
              to analyse payment transactions to offer customers high-quality
              services at competitive prices.
            </p>

            <h5>Transfer to third countries</h5>

            <p>USA</p>

            <h5>Further information:</h5>

            <p>
              <a href="https://www.paypal.com/myaccount/privacy/privacyhub?locale.x=de_AT">
                Privacy Policy
              </a>
            </p>

            <h3>Heat mapping and session recording</h3>

            <h4>Microsoft Clarity</h4>

            <h5>Name and contact information:</h5>

            <p>
              Microsoft Ireland Operations Limited, Z. Hd.: Data Protection
              Officer, One Microsoft Place, South County Business Park,
              Leopardstown, Dublin 18, Ireland.
            </p>
            <p>
              Contact information:{" "}
              <a href="https://www.microsoft.com/de-at/concern/privacy">
                https://www.microsoft.com/de-at/concern/privacy
              </a>
            </p>

            <h5>Purpose of the processing activity:</h5>

            <p>
              Heat mapping services are used to display the areas of a page
              where Users most frequently move the mouse or click. This shows
              where the points of interest are. These services make it possible
              to monitor and analyse web traffic and keep track of User
              behaviour. Some of these services may record sessions and make
              them available for a later visual playback.
            </p>

            <h5>Personal Data processed:</h5>

            <p>Log files and Usage Data</p>

            <h5>Legal Basis:</h5>

            <p>Consent of the data subject Art 6 (1) (a) GDPR</p>

            <h5>Further information</h5>

            <p>
              <a href="https://privacy.microsoft.com/de-de/privacystatement">
                Privacy Policy
              </a>
              &nbsp;
            </p>

            <h4>Hotjar</h4>

            <h5>Name and contact information:</h5>

            <p>
              Hotjar Ltd, Dragonara Business Centre, 5th Floor, Dragonara Road,
              Paceville St Julian’s STJ 3141, Malta
            </p>
            <p>e-mail: support@hotjar.com</p>

            <h5>Purpose of the processing activity:</h5>

            <p>
              Hotjar is used for heat mapping, session recording, and behavior
              analytics. This service allows Userbrain to understand how Users
              interact with the website, identifying user needs and optimizing
              services accordingly.
            </p>

            <h5>Personal Data processed:</h5>

            <p>Log files, usage data</p>

            <h5>Legal Basis:</h5>

            <p>Consent of the data subject Art 6 (1) (a) GDPR</p>

            <h5>Transfer to third countries:</h5>

            <p>
              Hotjar primarily processes data within the European Union.
              However, if data is transferred outside the EU, Hotjar ensures
              that such transfers comply with GDPR regulations through
              appropriate safeguards such as Standard Contractual Clauses.
            </p>

            <h5>Further information</h5>

            <p>
              <a href="https://www.hotjar.com/legal/policies/privacy/">
                Privacy Policy
              </a>
              &nbsp;
            </p>

            <h3>Hosting and backend infrastructure</h3>

            <p>
              This type of service has the purpose of hosting Data and files
              that enable Userbrain to run and be distributed as well as to
              provide a ready-made infrastructure to run specific features or
              parts of Userbrain.
            </p>

            <h4>DigitalOcean</h4>

            <h5>Name and contact information:</h5>

            <p>
              DigitalOcean LLC, 101 Avenue of the Americas 10th Floor New York,
              NY 10013, USA
            </p>
            <p>e-mail: privacy@digitalocean.com.</p>

            <h5>Purpose of the processing activity:</h5>

            <p>See 10.3.</p>

            <h5>Personal Data processed:</h5>

            <p>
              User data, log files, task data, usage data and payment
              information
            </p>

            <h5>Legal Basis:</h5>

            <p>
              Data processing is based on the legal ground of legitimate
              interest (Art 6 (1) (f) GDPR). Userbrain has a legitimate interest
              that the data processing is to be carried out in an efficient and
              economical way. By outsourcing the hosting and the backend
              infrastructure, customers may be offered a high-quality service at
              competitive prices.
            </p>

            <h5>Transfer to third countries</h5>

            <p>USA</p>

            <h5>Further information</h5>

            <p>
              <a href="https://www.digitalocean.com/legal/privacy/">
                Privacy Policy
              </a>
            </p>

            <h4>Amazon Web Services (AWS)</h4>

            <h5>Name and contact information:</h5>

            <p>
              Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, L-1855,
              Luxembourg
            </p>
            <p>e-mail: aws-EU-privacy@amazon.com</p>

            <h5>Purpose of the processing activity:</h5>

            <p>See 10.3.</p>

            <h5>Personal Data processed:</h5>

            <p>
              User data, task data, log files, usage data and payment
              information
            </p>

            <h5>Legal Basis:</h5>

            <p>
              Data processing is based on the legal ground of legitimate
              interest (Art 6 (1) (f) GDPR). Userbrain has a legitimate interest
              that the data processing is to be carried out in an efficient and
              economical way. By outsourcing the hosting and the backend
              infrastructure, customers may be offered a high-quality service at
              competitive prices.
            </p>

            <h5>Further information</h5>

            <p>
              <a href="https://aws.amazon.com/compliance/data-privacy/">
                Privacy Policy
              </a>
            </p>

            <h4>Open AI</h4>

            <h5>Name and contact information:</h5>

            <p>OpenAI, Inc., 3180 18th Street, San Francisco, CA 94110, USA</p>
            <p>e-mail: support@openai.com</p>

            <h5>Purpose of the processing activity:</h5>

            <p>
              Userbrain utilizes services provided by OpenAI to analyze user
              testing data, enhancing the accuracy and depth of test analysis to
              save users time and effort.
            </p>

            <h5>Personal Data processed:</h5>

            <p>Task Data</p>

            <h5>Legal Basis:</h5>

            <p>Consent of the data subject Art 6 (1) (a) GDPR</p>

            <h5>Transfer to third countries</h5>

            <p>
              OpenAI operates servers and data centers outside the EU, including
              the United States. Personal data processed by OpenAI is therefore
              transferred to third countries.
            </p>

            <h5>Further information</h5>

            <p>
              For more details on OpenAI’s privacy practices, users can refer to{" "}
              <a href="https://openai.com/policies/privacy-policy/">
                OpenAI’s Privacy Policy
              </a>
              .
            </p>

            <h4>Sentry</h4>

            <h5>Name and contact information:</h5>

            <p>
              Functional Software Inc., 45 Fremont Street, 8th Floor, San
              Francisco, CA 94105, USA
            </p>
            <p>
              e-mail: <a href="mailto:security@sentry.io">security@sentry.io</a>
            </p>

            <h5>Purpose of the processing activity:</h5>

            <p>
              This type of service allows Userbrain to monitor the use and
              behaviour of its components so that its performance, operation,
              maintenance and troubleshooting can be improved. Which personal
              data are processed depends on the characteristics and mode of
              implementation of these services, whose function is to filter the
              activities of Userbrain.
            </p>

            <h5>Personal Data processed:</h5>

            <p>User data, log files, task data and usage data</p>

            <h5>Legal Basis:</h5>

            <p>
              This service is necessary for the performance of the contract
              according to Art 6 (1) (b) GDPR.
            </p>

            <h5>Transfer to third countries</h5>

            <p>USA</p>

            <h5>Further information</h5>

            <p>
              <a href="https://sentry.io/privacy/">Privacy Policy</a>
            </p>

            <h3>Managing contacts and sending messages</h3>
            <ol>
              <li>
                <h4>Mailchimp</h4>
                <ol>
                  <li>
                    <h5>Name and contact information:</h5>
                  </li>
                </ol>
              </li>
            </ol>

            <p>
              The Rocket Science Group LLC., 675 Ponce de Leon Ave NE, Suite
              5000, Atlanta, Georgia 30308, USA
            </p>
            <p>
              e-mail:{" "}
              <a href="mailto:personaldatarequests@mailchimp.com">
                personaldatarequests@mailchimp.com
              </a>
            </p>

            <h5>Purpose of the processing activity:</h5>

            <p>
              By registering on the mailing list or for the newsletter, the User
              will be added to the respective contact list. These Users will
              receive email messages containing information of commercial or
              promotional nature concerning Userbrain.
            </p>

            <h5>Personal Data processed:</h5>

            <p>User data, log files and usage data</p>

            <h5>Legal Basis:</h5>

            <p>Consent of the data subject Art 6 (1) (a) GDPR</p>

            <h5>Transfer to third countries</h5>

            <p>USA</p>

            <h5>Further information</h5>

            <p>
              <a href="https://mailchimp.com/legal/privacy/">Privacy Policy</a>
            </p>

            <h4>Sendgrid</h4>

            <h5>Name and contact information:</h5>

            <p>
              Twilio Ireland Limited, 25-28 North Wall Quay, Dublin 1, Ireland
            </p>
            <p>e-mail: privacy@twilio.com</p>

            <h5>Purpose of the processing activity:</h5>

            <p>
              This type of service makes it possible to manage a database of
              email contacts, phone contacts or any other contact information to
              communicate with the User. These services may also collect data
              concerning the date and time when the message was viewed by the
              User, as well as when the User interacted with it, such as by
              clicking on links included in the message.
            </p>

            <h5>Personal Data processed:</h5>

            <p>User data, log files and usage data</p>

            <h5>Legal Basis:</h5>

            <p>
              Data processing is based on the legal ground of legitimate
              interest (Art 6 (1) (f) GDPR). Userbrain has a legitimate interest
              that the data processing is to be carried out in an efficient and
              economical way. By outsourcing the e-mail address to a management
              and message sending service, customers may be offered a
              high-quality service at competitive prices.
            </p>

            <h5>Transfer to third countries</h5>

            <p>USA</p>

            <h5>Further information</h5>

            <p>
              <a href="https://sendgrid.com/policies/privacy/">
                Privacy Policy
              </a>
            </p>

            <h3>Handling customer relationship</h3>
            <ol>
              <li>
                <h4>Close</h4>
                <ol>
                  <li>
                    <h5>Name and contact information:</h5>
                  </li>
                </ol>
              </li>
            </ol>

            <p>lastic Inc, Po Box 1145, Jackson, WY 83001, USA</p>
            <p>e-mail: dpo@close.com</p>

            <h5>Purpose of the processing activity:</h5>

            <p>
              Customer relationship management (CRM) is a technology for
              managing all of Userbrians relationships and interactions with its
              Users and potential users. This system helps Usererbrain to stay
              connected to its Users and handel all User requests.
            </p>

            <h5>Personal Data processed:</h5>

            <p>User data, log files, task data and usage data</p>

            <h5>Legal Basis:</h5>

            <p>
              Data processing is based on the legal ground of legitimate
              interest (Art 6 (1) (f) GDPR). Userbrain has a legitimate interest
              that the data processing and the handling user requests are to be
              carried out in an efficient and economical way. By outsourcing the
              CRM service, customers may be offered a high-quality service at
              competitive prices.
            </p>

            <h5>Transfer to third countries</h5>

            <p>USA</p>

            <h5>Further information</h5>

            <p>
              <a href="https://www.close.com/privacy">Privacy Policy</a>
            </p>

            <h4>Dovetail</h4>

            <h5>Name and contact information:</h5>

            <p>
              Dovetail Research Pty Ltd, Level 1, 276 Devonshire Street, Surry
              Hills, 2010, NSW, Australia
            </p>
            <p>e-mail: legal@dovetailapp.com</p>

            <h5>Purpose of the processing activity:</h5>

            <p>
              Customer relationship management (CRM) is a technology for
              managing all of Userbrians relationships and interactions with its
              Users and potential Users. This system helps Usererbrain to stay
              connected to its Users and handle all User requests.
            </p>

            <h5>Personal Data processed:</h5>

            <p>User data, log files, task data and usage data</p>

            <h5>Legal Basis:</h5>

            <p>
              Data processing is based on the legal ground of legitimate
              interest (Art 6 (1) (f) GDPR). Userbrain has a legitimate interest
              that the data processing and the handling User requests are to be
              carried out in an efficient and economical way. By outsourcing the
              CRM service, customers may be offered a high-quality service at
              competitive prices.
            </p>

            <h5>Transfer to third countries</h5>

            <p>Australia</p>

            <h5>Further information</h5>

            <p>
              <a href="https://dovetailapp.com/help/privacy-policy/">
                Privacy Policy
              </a>
            </p>

            <h4>Calendly</h4>

            <h5>Name and contact information:</h5>

            <p>
              Calendly LLC, 271 17th St NW, Ste 1000, Atlanta, Georgia, 30363,
              USA
            </p>
            <p>e-mail: support@calendly.com</p>

            <h5>Purpose of the processing activity:</h5>

            <p>This service helps Usererbrain to handly appointments.</p>

            <h5>Personal Data processed:</h5>

            <p>User data, log files and usage data</p>

            <h5>Legal Basis:</h5>

            <p>
              Data processing is based on the legal ground of legitimate
              interest (Art 6 (1) (f) GDPR). Userbrain has a legitimate interest
              to handle appointments through a software service in order to meet
              the Users expectaions.
            </p>

            <h5>Transfer to third countries</h5>

            <p>USA</p>

            <h5>Further information</h5>

            <p>
              <a href="https://calendly.com/en/privacy">Privacy Policy</a>
            </p>

            <h4>Typeform</h4>

            <h5>Name and contact information:</h5>

            <p>
              Typeform S.L., Carrer Bac de Roda, 163, 08018 Barcelona, Spain
            </p>
            <p>e-mail: support@typeform.com</p>

            <h5>Purpose of the processing activity:</h5>

            <p>
              This service is used to create and manage forms and surveys used
              in user testing and feedback collection.
            </p>

            <h5>Personal Data processed:</h5>

            <p>User data</p>

            <h5>Legal Basis:</h5>

            <p>Consent of the data subject Art 6 (1) (a) GDPR</p>

            <h5>Transfer to third countries</h5>

            <p>
              Typeform stores and processes data on servers located in the
              European Union. However, in cases where data may be transferred
              outside the EU, Typeform ensures compliance with GDPR requirements
              through the implementation of Standard Contractual Clauses.
            </p>

            <h5>Further information</h5>

            <p>
              <a href="https://admin.typeform.com/to/dwk6gt">Privacy Policy</a>
            </p>

            <h3>Managing blog content and Users data</h3>

            <h5>Name and contact information:</h5>

            <p>
              (KeyCDN)proinity LLC, Reichenauweg 1, 8272 Ermatingen, Switzerland
            </p>

            <h5>Purpose of the processing activity:</h5>

            <p>
              This service makes content available to Users, so they can access
              content faster through lower latency.
            </p>

            <h5>Personal Data processed:</h5>

            <p>User data, log files, task data and usage data</p>

            <h5>Legal Basis:</h5>

            <p>
              Data processing is based on the legal ground of legitimate
              interest (Art 6 (1) (f) GDPR). Userbrain has a legitimate interest
              that Users can access content faster and therefore offer a
              high-quality service.
            </p>

            <h5>Further information</h5>

            <p>
              <a href="https://www.keycdn.com/privacy">Privacy Policy</a>
            </p>

            <h3>Content commenting by Disqus</h3>

            <h5>Name and contact information:</h5>

            <p>
              Disqus, Inc., 717 Market Street, Suite 700, San Francisco, CA
              94103, USA
            </p>
            <p>
              e-mail: <a href="mailto:privacy@disqus.com">privacy@disqus.com</a>
            </p>

            <h5>Purpose of the processing activity:</h5>

            <p>
              Content commenting services allow Users to make and publish their
              comments on the contents of Userbrain. The comments are available
              to the public. Depending on the settings chosen by the Controller,
              Users may also leave anonymous comments. If there is an email
              address among the Personal Data provided by the User, it may be
              used to send notifications of comments on the same content. Users
              are responsible for the content of their own comments.
            </p>

            <h5>Personal Data processed:</h5>

            <p>User data, log files and usage data</p>

            <h5>Legal Basis:</h5>

            <p>Consent of the data subject Art 6 (1) (a) GDPR</p>

            <h5>Transfer to third countries</h5>

            <p>USA</p>

            <h5>Further information:</h5>

            <p>
              <a href="https://help.disqus.com/customer/portal/articles/466259-privacy-policy">
                Privacy Policy
              </a>
            </p>

            <h3>Visiting our website</h3>

            <p>
              When visiting our website, Userbrain processes your personal data.
            </p>

            <h5>Purpose of the processing activity:</h5>

            <p>
              The purpose of the processing of personal data of Users is to
              ensure that our website is displayed correctly and to improve our
              Website.
            </p>

            <h5>Personal Data processed:</h5>

            <p>Log files and usage data</p>

            <h5>Legal Basis:</h5>

            <p>
              Data processing is based on the legal basis of legitimate interest
              (Art 6 (1) (f) GDPR) and carried out in the correct and secure
              operation of our website and in continuously improving our offers
              and our website.
            </p>

            <h3>Fanpage</h3>

            <p>
              Userbrain operates a so called “fan page” on various social media
              plattforms. By clicking on the respective link, you will be
              forwarded to our “fan page” on the respective social media page.
              The respective operator of the social media page and Userbrain are
              joint controller.
            </p>

            <h5>Purpose of the processing activity:</h5>

            <p>
              The purpose of this processing is to increase our web presence on
              various social media channels.
            </p>

            <h5>Personal data processed</h5>

            <p>Log files are sent to the respective website operator.</p>

            <h5>Legal Basis</h5>

            <p>
              Data processing is based on the legal basis of legitimate interest
              (Art 6 (1) (f) GDPR) and is aimed at increasing our web presence
              and taking marketing measures.
            </p>

            <h4>Facebook</h4>

            <h5>Name and contact information:</h5>

            <p>
              Meta Plaftorms Ireland Ltd, 4 Grand Canal Square, Grand Canal
              Harbour, Dublin 2, Irland
            </p>
            <p>
              Contact:{" "}
              <a href="https://www.facebook.com/help/contact/2061665240770586">
                https://www.facebook.com/help/contact/2061665240770586
              </a>
            </p>

            <h5>Further information</h5>

            <p>
              <a href="https://www.facebook.com/about/privacy/update">
                Privacy Policy
              </a>
            </p>

            <h5>Information about joint controller</h5>

            <h5>
              <a href="https://www.facebook.com/legal/terms/page_controller_addendum">
                <strong>
                  https://www.facebook.com/legal/terms/page_controller_addendum
                </strong>
              </a>
            </h5>
            <p>
              <a href="https://www.facebook.com/legal/controller_addendum">
                https://www.facebook.com/legal/controller_addendum
              </a>
            </p>

            <h4>LinkedIn</h4>

            <h5>Name and contact information:</h5>

            <p>
              LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland
            </p>
            <p>Contact: https://www.linkedin.com/help/linkedin/ask/ppq</p>

            <h5>Further information</h5>

            <p>
              <a href="https://www.linkedin.com/legal/privacy-policy?">
                Privacy Policy
              </a>
            </p>

            <h5>Information about joint controller</h5>

            <p>
              <a href="https://legal.linkedin.com/pages-joint-controller-addendum">
                https://legal.linkedin.com/pages-joint-controller-addendum
              </a>
            </p>

            <h4>Twitter</h4>

            <h5>Name and contact information:</h5>

            <p>
              Twitter International Company, One Cumberland Place, Fenian
              Street, Dublin 2, D02 AX07 IRLAND
            </p>
            <p>
              Kontakt:
              https://twitter.ethicspointvp.com/custom/twitter/forms/data/form_data.asp
            </p>

            <h5>Further information</h5>

            <p>
              <a href="https://twitter.com/en/privacy">Privacy Policy</a>
            </p>

            <h4>Instagram</h4>

            <h5>Name and contact information:</h5>

            <p>
              Meta Plaftorms Ireland Ltd, 4 Grand Canal Square, Grand Canal
              Harbour, Dublin 2, Irland
            </p>
            <p>
              Contact: https://www.facebook.com/help/contact/2061665240770586
            </p>

            <h5>Further information</h5>

            <p>
              <a href="https://help.instagram.com/519522125107875/?maybe_redirect_pol=0">
                Privacy Policy
              </a>
            </p>

            <h5>Information about joint controller</h5>

            <p>
              <a href="https://de-de.facebook.com/legal/terms/page_controller_addendum">
                https://de-de.facebook.com/legal/terms/page_controller_addendum
              </a>
            </p>
            <p>
              <a href="https://www.facebook.com/legal/controller_addendum">
                https://www.facebook.com/legal/controller_addendum
              </a>
            </p>

            <h3>Marketing</h3>

            <p>
              Userbrain processes personal data as part of marketing activities.
            </p>

            <h5>Purpose of the processing activity:</h5>

            <p>
              The purpose of data processing is the organization of marketing
              activities and to inform Users about news, promotions, services
              and successes of Userbrain.
            </p>

            <h5>Personal Data processed:</h5>

            <p>User data, log files and usage data</p>

            <h5>Legal Basis:</h5>

            <p>
              Data processing is based on the legal basis of legitimate interest
              (Art 6 (1) (f) GDPR). We have a legitimate interest in taking
              marketing measures and inform Users about aour services.
            </p>

            <h2>The rights of Users</h2>
            <p>
              Users may exercise certain rights regarding their data processed
              by the Controller.
            </p>
            <p>In particular, Users have the following rights to:</p>
            <ul>
              <li>
                <p>
                  <strong>Withdraw their consent at any time.</strong>
                  &nbsp;Users have the right to withdraw consent where they have
                  previously given their consent to the processing of their
                  personal data with immediate effect at any time. The legality
                  of the processing of your personal data up to the point of
                  withdrawal is not affected by the withdrawal of consent.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Object to processing of their data (Art 21 GDPR).
                  </strong>
                  &nbsp;Users have the right to object to the processing of
                  their data if the processing is carried out on a legal basis
                  other than consent. Users must know that, however, should
                  their personal data be processed for direct marketing
                  purposes, they can object to that processing at any time
                  without providing any justification. To learn, whether the
                  Controller is processing personal data for direct marketing
                  purposes, Users may refer to the relevant sections of this
                  document.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Informatiton and access to their data (Art 15 GDPR).
                  </strong>
                  &nbsp;Users have the right to learn if data is being processed
                  by the Controller, obtain disclosure regarding certain aspects
                  of the processing and obtain a copy of the data undergoing
                  processing.
                </p>
              </li>
              <li>
                <p>
                  <strong>Verify and seek rectification (Art 16 GDPR).</strong>
                  &nbsp;Users have the right to verify the accuracy of their
                  data and ask for it to be updated or corrected.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Restrict the processing of their data (Art 18 GDPR).
                  </strong>
                  &nbsp;Users have the right, under certain circumstances, to
                  restrict the processing of their data. In this case, the
                  Controller will not process their data for any purpose other
                  than storing it.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Have their personal data deleted or otherwise removed (Art
                    17 GDPR).
                  </strong>
                  &nbsp;Users have the right, under certain circumstances, to
                  obtain the erasure of their data from the Controller.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Receive their data and have it transferred to another
                    Controller (Art 20 GDPR).
                  </strong>
                  &nbsp;Users have the right to receive their Data in a
                  structured, commonly used and machine-readable format and, if
                  technically feasible, to have it transmitted to another
                  Controller without any hindrance. This provision is applicable
                  provided that the data is processed by automated means and
                  that the processing is based on the User's consent, on a
                  contract of which the User is part or on pre-contractual
                  obligations thereof.
                </p>
              </li>
              <li>
                <p>
                  <strong>Lodge a complaint.</strong>&nbsp;Users have the right
                  to bring a claim before their competent data protection
                  authority.
                </p>
              </li>
            </ul>
            <p>
              Any requests to exercise User rights can be directed to the
              Controller through the contact details provided in this document.
              These requests can be exercised free of charge and will be
              addressed by the Controller as early as possible and always within
              one month.
            </p>

            <h2>Cookie Policy</h2>
            <p>
              Userbrain uses Trackers. To learn more, the User may consult
              the&nbsp;Cookie Policy.
            </p>

            <h2>Definitions and legal references</h2>
            <p>
              Where this document uses the terms defined in Regulation (EU)
              2016/679, those terms shall have the same meaning as in that
              Regulation.
            </p>
            <p>Latest update: 29.08.2024</p>
          </HtmlContent>
        </div>
      </article>
    </Layout>
  );
};

export default Privacy;
